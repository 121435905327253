import * as React from "react";
import Layout from '../components/global/Layout';
import Entrance from '../components/home-page/Entrance';
import HomeDescription from '../components/home-page/HomeDescription';
import Address from '../components/home-page/Address';

const IndexPage = () => {
  return (
    <Layout>
      <Entrance />
      <HomeDescription />
      <Address />
    </Layout>
  );
};

export default IndexPage;
