import React from 'react';
import * as styles from './Address.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Address = () => {
    return (
        <div className={styles.container}>
            <div
                data-sal="slide-right"
                data-sal-duration="1000"
                data-sal-delay="3000"
                data-sal-easing="ease"
                className={styles.addressContainer}
            >
                <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.markerIcon} />
                <p>Scheelegatan 4 </p>
                <p>112 23</p>
                <p>Stockholm</p>

            </div>
            <div
                data-sal="slide-left"
                data-sal-duration="1000"
                data-sal-delay="3000"
                data-sal-easing="ease"
                className={styles.googleMap}>
                <iframe
                    title='address'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2035.215229166867!2d18.04137771619758!3d59.329360418062045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f77d887b53349%3A0x27ff075e4f303bff!2sScheelegatan%204%2C%20112%2023%20Stockholm!5e0!3m2!1sen!2sse!4v1643475615591!5m2!1sen!2sse"
                    loading="lazy"
                />
            </div>
        </div>
    );
};

export default Address;
