import React from "react";
import * as styles from "./Entrance.module.css";
import { StaticImage } from "gatsby-plugin-image";

const Entrance = () => {
  return (
    <>
      <div className={styles.container}>
        <StaticImage
          src={"../../images/cheveu_logo_transparent.png"}
          objectFit="contain"
          className={styles.Logo}
          placeholder="tracedSVG"
          alt="Cheveu Logo"
        />
        <div
          className={styles.buttonContainer}
          data-sal="fade-in"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <p className={styles.OpeningTag}>Din frisör på Kungsholmen</p>
          <a
            href="https://www.bokadirekt.se/places/cheveu-37684"
            target="_blank"
            rel="noreferrer"
            className={styles.bookNowButton}
          >
            Boka tid
          </a>
        </div>
      </div>
      <div className={styles.entranceImagesContainer}>
        <div
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture9.jpg"}
            alt="pic"
            placeholder="blurred"
            className={styles.EntranceImage}
          />
        </div>
        <div
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/image2.png"}
            alt="pic"
            placeholder="blurred"
            className={styles.EntranceImage}
          />
        </div>
        <div
          data-sal="slide-left"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/image1.png"}
            alt="pic"
            placeholder="blurred"
            className={styles.EntranceImage}
          />
        </div>
      </div>
    </>
  );
};

export default Entrance;
