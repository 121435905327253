import React from "react";
import * as styles from "./HomeDescription.module.css";
import { StaticImage } from "gatsby-plugin-image";

const HomeDescription = () => {
  return (
    <div>
      <div className={styles.container}>
        <h2
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="ease"
          className={styles.heading}
        >
          Cheveu
        </h2>
        <div className={styles.description}>
          <div data-sal="fade" data-sal-duration="1000" data-sal-easing="ease">
            <p>
              Hos oss får du professionella behandlingar med hög kvalité och
              engagemang i en rofylld miljö.
            </p>
            <p>
              Vi lyssnar, diskuterar, ger gärna förslag och rekommendationer för
              att du ska trivas med din frisyr.
            </p>
          </div>
          <div data-sal="fade" data-sal-duration="1000" data-sal-easing="ease">
            <p>
              Vi vill vägleda dig till en så skonsam behandling som möjligt
              beroende på utgångsläge och önskemål.
            </p>
            <p>
              Vår gröna tanke genomsyrar hela verksamheten på olika sätt. Vår
              ambition är att erbjuda modernt hårvård som belastar miljö och
              hälsa så lite som möjligt.
            </p>
            <p>
              Vi har haft många nöjda kunder hos Cheveu under åren. Du kan se
              våra omdömen&nbsp;
              <a
                className={styles.reviewLink}
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/search?q=cheveu+kungsholmen&ei=DPsRYse1AYiPxc8P5bmygAQ&ved=0ahUKEwjH67Dn7Y32AhWIR_EDHeWcDEAQ4dUDCA4&uact=5&oq=cheveu+kungsholmen&gs_lcp=Cgdnd3Mtd2l6EAMyBwghEAoQoAE6CwgAELADEAcQChAeOgcIABCwAxAKOgkIABCwAxAHEB46CggAEIAEELADEAo6EgguEMcBEK8BEMgDELADEAoYADoMCC4QyAMQsAMQChgAOgQILhAKOgoILhDHARCjAhAKOgQIABAKOgsILhCABBDHARCjAjoFCC4QgAQ6BQgAEIAEOgsILhCABBDHARCvAToICAAQgAQQyQM6BwguEIAEEAo6BwgAEIAEEAo6BggAEBYQHjoICAAQFhAKEB46BQghEKABOgQIABANOgQILhANSgQIQRgBSgQIRhgAUNYCWKkjYPsjaAJwAHgAgAGBAYgB7wySAQM3LjmYAQCgAQHIAQzAAQHaAQQIABgI&sclient=gws-wiz#lrd=0x465f9dddfe8adc8f:0xcb029fb1bd75c984,1,,,"
              >
                här
              </a>
              &nbsp;och&nbsp;
              <a
                className={styles.reviewLink}
                target="_blank"
                rel="noreferrer"
                href="https://www.bokadirekt.se/places/cheveu-37684"
              >
                här.
              </a>
            </p>
            <p>Välkommen!</p>
          </div>
        </div>
      </div>
      <div className={styles.KarwanContainer}>
        <h2
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="ease"
          className={styles.heading}
        >
          Om Din Frisör, Karwan
        </h2>
        <div
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-easing="ease"
          className={styles.entranceImagesContainer}
        >
          <StaticImage
            src={"../../images/karwan1.jpg"}
            alt="pic"
            placeholder="blurred"
            className={styles.EntranceImage}
          />
        </div>
        <div className={styles.description}>
          <div data-sal="fade" data-sal-duration="1000" data-sal-easing="ease">
            <p>
              Jag älskar mitt kreativa yrke med både färg och form. Att skapa
              individuellt anpassad design är ett förtroende och ett
              privilegium.
            </p>
            <p>
              Det är en förmån att få vara entreprenör just här och just nu i
              detta fantastiska yrke som nu är på väg in i en hållbar epok.
            </p>
          </div>
          <div data-sal="fade" data-sal-duration="1000" data-sal-easing="ease">
            <p>
              Jag är specialiserad stylist inom dam och herr. Barberare,
              klippning och färgtekniker.
            </p>
            <p>
              Jag är utbildad i Syrien, jobbat där och har över 16 års
              erfarenhet inom frisör branschen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDescription;
